.custom-material-tile {
    background: white;
    border: none;
    border-radius: 6px;
    box-shadow: 0 2px 4px #adadad, 0 2px 9px #e4e4e4;
    padding: 10px;
    position: relative;
    // z-index: 10;
}
.react-grid-layout.layout{
    border: 2px dashed #d1d1d1;
    border-radius: 10px;
    min-height: 420px;
    margin-top: 5px;
}

.react-grid-item.react-grid-placeholder {
    background-color: #ececec;
    border: 2px dashed #888e93;
    // z-index: 1;
}
