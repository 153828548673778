$blue-anthracite: #102b3f !default;
$font-weight-bold: 600 !default;
$blue: #00a1ed !default;
$cyan-light-1: #e8f5f5 !default;
$cyan-light-2: #d3e6e6 !default;
$transition-timing-function: ease-in-out !default;
$transition-duration: 0.2s !default;
$font-weight-normal: 400 !default;
$white: #ffffff !default;
$border-radius-sm: 26px;
$blue-gothic: #6a9191 !default;

.active-form-root {
  background-color: #e8f5f5;
  border-radius: 25px;
  padding: 65px 130px 40px;
  color: #102b3f;
}

.active-form-container {
  display: flex;
  flex-direction: column;
  color: $blue-anthracite;
  background-color: #fff;
  border-radius: 25px;
  padding: 40px;
  margin-top: 30px;
}

.active-form-main-title {
  display: inline-block;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin: 15px 15px 15px 0;
}

.active-form-description {
  font-size: 16px;
}

.active-form-header {
  font-size: 18px;
  margin-bottom: 30px;
  font-weight: $font-weight-bold;
}
.active-form-title {
  font-size: 1.5em;
  line-height: 1.1em;
  margin-bottom: 18px;
  font-weight: $font-weight-bold;
}
.active-form-content {
  flex: auto;
  padding-top: 3px;
}
.content a{
  color: $blue;
  &:hover {
    color: $blue;
  }
}
.active-form-content-wrapper {
  margin-bottom: 34px;
  display: flex;
  flex-direction: row;
}

.active-form-checkbox {
  display: block;
  position: relative;
  min-width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid $cyan-light-2;
  margin: 0 10px 20px 0;
}

.active-form-icon {
  position: absolute;
  width: 11px;
  height: 11px;
  top: 7px;
  left: 7px;
  z-index: 2;
  transition: opacity $transition-duration $transition-timing-function;
}

.active-form-item {
  margin-bottom: 20px;
}
.active-form-item-checkbox {
  composes: item;
  display: flex;
  flex-direction: row;
}


.active-form-checkbox-label {
  font-size: 14px;
  padding-top: 5px;
  font-weight: $font-weight-bold;
}

.active-form-label {
  display: block;
  position: static;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 10px;
  color: $blue-anthracite;
  font-weight: $font-weight-bold;
  margin: 0 0 10px 0;
}
.active-form-value {
  margin: 0 0 20px 0;
  font-size: 1rem;
  font-weight: $font-weight-bold;
}

.tooltip-container {
  position: relative;
}

.active-form-tooltip-root {
  position: relative;
  cursor: default;
  display: inline-block;
  margin-left: 5px;
}

.active-form-tooltip {
  position: relative;
  width: 23px;
  height: 23px;
  font-weight: $font-weight-bold;
  color: $white;
  background-color: $blue-anthracite;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover > .active-form-tooltip-message {
    visibility: visible;
    opacity: 1;
  }

  &:after {
    content: "";
    display: inline-block;
    border-left: 8px solid $blue-anthracite;
    border-right: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.active-form-tooltip-message {
  padding: 18px;
  font-size: 12px;
  line-height: 15px;
  font-weight: $font-weight-normal;
  min-width: 200px;
  position: absolute;
  bottom: 30px;
  left: calc(-200px + 23px + 25px);
  color: $white;
  background-color: $blue-anthracite;
  border-radius: $border-radius-sm;
  opacity: 0;
  visibility: hidden;
  transition-property: opacity;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
}

.active-form-select-item {
  margin: 0;
  transition: transform $transition-duration $transition-timing-function;
  white-space: nowrap;
  &:hover {
    transform: translate3d(10px, 0, 0);
  }
}
.active-form-select-item-button {
  color: $blue-gothic;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 5px 20px;
  width: 100%;
}

.active-form-select-root {
  background-color: $white;
  border-radius: 20px;
  overflow: hidden;
  position: absolute;
  border: 1px solid $blue-gothic;
  z-index: 2;
}

.active-form-select-header {
  color: $blue-anthracite;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: 0;
  line-height: 42px;
  text-align: left;
  padding: 0 20px;
  width: 100%;
  transition-property: background-color;
  transition-duration: $transition-duration;
  transition-timing-function: $transition-timing-function;
}

.active-form-select-header-closed {
  @extend .active-form-header;
  background-color: $cyan_light-1;
}

.active-form-select-arrow {
  color: $blue-anthracite;
  width: 13px;
}

.active-form-select-check-wrapper {
  display: inline-flex;
  position: relative;
  align-items: center;
  margin: 0;
}

.active-form-select-check {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  z-index: 3;
  border: 1px solid $cyan-light-2;
  background-color: $white;
}

.active-form-select-check-text {
  align-self: center;
  padding-left: 15px;
  font-weight: $font-weight-bold;
  font-size: 14px;
  color: $blue;
}

.active-form-select-container {
  flex-grow: 1;
}

.active-form-select-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.active-form-select-inside-wrapper {
  width: 100%;
  height: 48px;
}

.active-form-select-list {
  list-style: none;
  padding: 0 5px 10px 5px;
  max-height: 220px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
}

.active-form-select-expand-arrow {
  margin-left: 10px;
}