// CUSTOM

.fullscreen:-moz-full-screen {
  height: 100%;
}

.fullscreen:-webkit-full-screen {
  height: 100%;
}

.fullscreen:-ms-fullscreen {
  height: 100%;
}

.fullscreen:fullscreen {
  height: 100%;
}

.fullscreen {
  margin-bottom: 10px;
  width: 100%;
  height: auto;
}

.sidepanel {
  position: absolute;
  top: 50px;
  right: 65px;
  width: 400px;
  height: 250px;
  display: none;
  border-radius: 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  margin-bottom: 24px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
  overflow-y: auto;
}

.sidepanel2 {
  position: absolute;
  top: 315px;
  right: 65px;
  width: 400px;
  height: auto;
  display: none;
  border-radius: 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  margin-bottom: 24px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
}

.sidepanel3 {
  position: absolute;
  top: 110px;
  left: 65px;
  width: 400px;
  height: auto;
  display: none;
  border-radius: 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  margin-bottom: 24px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
}

.left-panel {
  position: absolute;
  top: 6em;
  left: 7em;
  width: 400px;
  display: none;
  border-radius: 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
}

.sidepanel-heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.12);
  height: 50px;
  font-size: 16px;
  padding: 14px 22px;
}

.sidepanel-body {
  padding: 14px 22px;
  height: 100%;
}

.custom-mouse-position {
  position: absolute;
  left: 10px;
  bottom: 40px;
  color: white;
  background-color: grey;
  padding: 2px 4px;
}

/* HIDE RADIO */
[type="radio"].thumbnil {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type="radio"].thumbnil + img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type="radio"].thumbnil:checked + img {
  outline: 2px solid #f00;
}

/* for measurement */

.tooltip {
  position: relative;
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
}

.tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.tooltip-static {
  background-color: #ffcc33;
  color: black;
  border: 1px solid white;
}

.tooltip-measure:before,
.tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.tooltip-static:before {
  border-top-color: #ffcc33;
}

/* autocomplete */
.autocomplete {
  position: relative;
  display: inline-block;
}

.autocomplete-items {
  color: black;
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9;
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}

/* end of autocomplete css */

// popup css

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  border-radius: 10px;
  border: 1px solid #3b8fc4;
  bottom: 25px;
  left: -53px;
  min-width: 280px;
  display: none;
  color: black;
  width: 440px;
  height: 350px;
}

.popup-header {
  padding: 15px 15px 1px 15px;
  background-color: #3b8fc4;
  border-radius: 7px 7px 0px 0px;
  color: white;
}

.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}

.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 12px;
  right: 12px;
  color: white;
  cursor: pointer;
}

.ol-popup-closer:hover {
  color: mediumseagreen;
}

.ol-popup-closer:after {
  content: "✖";
}

.business-id-column {
  width: 100%;
  position: relative;
  .business-id-start {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60%;
  }

  .business-id-end {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }

  .business-id-copy {
    background-color: #ced4da;
    border-radius: 50px;
    position: absolute;
    right: 2%;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .business-id-copied {
    color: #fff;
  }
}

.attachment-file-name {
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  transform: translateY(6px);
}

.copiable-field {
  width: 100%;
  position: relative;

  .copy-icon {
    position: absolute;
    right: 2%;
    top: 15%;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .copied {
    color: #fff;
  }
}

.modal-xxl {
  max-width: 1800px;
}

.tooltip-max-width {
  max-width: 100%;
}

.listing-btn-container {
  //text-align: right!important;
}

.dot {
  height: 1rem;
  width: 1rem;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
}

.h-50 {
  height: 50%;
}

.text-transform-none {
  text-transform: none;
}

// end of popup css

.docs-tree-button {
  display: flex;
  flex-direction: column;
  height: 400px;
}

.docs-tree-column {
  min-width: 325px;
  max-width: 325px;
}

.is-invalid-select {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right 25px;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

input[type="file"] {
  white-space: normal;
  word-wrap: break-word;
  overflow: auto;
}

.error-page-icon {
  font-size: 7rem;
}

.main-menu > .metismenu-container > .metismenu-item:last-child {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: content-box;
  overflow: visible;
  display: block;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.no-dragging {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.winter-photo {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 28px;
  svg {
    fill: #d5d5d5;
    stroke: #0041fd;
    path {
      stroke-width: 30px;
    }
  }
}

.password-input-wrapper {
  position: relative;
  width: 100%;
}
.password-input-eye {
  position: absolute;
  top: 27%;
  font-size: 1.1rem;
  right: 30px;
  &:hover {
    color: $primary;
    cursor: pointer;
  }
}

.password-hidden-input {
  font-family: text-security-disc;
  -webkit-text-security: disc;
}

.permissions-flexbox {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 500px;
  overflow-x: auto;
}
.map-pin {
  height: 32px;
  width: 20px;
  margin-top: -32px;
  margin-left: -10px;
  background: url("~src/assets/utils/images/icons/map_pin.png");
}

.gridFilterAutocomplete__control {
  max-height: 32px;
  min-height: 32px !important;
  .gridFilterAutocomplete__placeholder {
    top: 35%;
  }
  .gridFilterAutocomplete__indicators {
    max-height: 30px;
  }
  .gridFilterAutocomplete__input {
    input {
      margin-top: -2px;
      box-shadow: none !important;
    }
  }
  .gridFilterAutocomplete__single-value {
    margin-top: -2px;
  }
}
.gridFilterAutocomplete__menu {
  margin-top: 2px !important;
  color: inherit !important;
  text-decoration: none;
}

.enable-pointer-event {
  pointer-events: all !important;
}

.BurgerBox {
  height: 12px !important;
}

.Burger {
  transform: scale(0.8);
}

.ml-24px {
  margin-left: 24px !important;
}

.employeeAlert {
  margin-bottom: 0;
  margin-top: 1.72rem;
  padding: 0.58rem 1.25rem;
}

div[class^="gridFilterAutocomplete__option"],
div[class*="gridFilterAutocomplete__option"] {
  color: #495057 !important;
  font-weight: normal;
  text-decoration: none;
  text-align: left;
}

.gridFilterAutocomplete__option--is-selected,
.gridFilterAutocomplete__option--is-focused {
  background-color: rgb(221, 241, 247) !important;
}

.searchProductRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid gray;
  padding-bottom: 15px;
}

.lastSearchProductRow {
  @extend .searchProductRow;
  border: none;
  padding-bottom: 0px;
}

.twoColumnsGridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.notDisplayed {
  display: none;
}

.newPeopleList {
  list-style: none;
  padding: 5px;
  font-weight: 600;
}

.newPeopleListItem {
  display: flex;
  word-break: initial;
}

.tableColumnStatus {
  text-align: left;
}

.selectCreatorContainer {
  width: 100%;
  max-width: 300px;
}

.optionContainer {
  margin: 20px 0;
}

.optionInnerContainer {
  display: flex;
}

.selectCreatorInputTitle {
  margin-bottom: 24px;
}

.selectCreatorButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selectCreatorButtonsContainer i:first-child {
  margin-bottom: 10px;
}

/* bigger label title */
label[title][for] {
  margin: -8px -8px 0 -8px;
  padding: 8px;
  width: calc(100% + 16px);
}

.centerButton {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-datepicker {
  .react-datepicker__navigation--next--with-time {
    right: 130px;
  }
}

.editGroupAddPhotoSection {
  margin-top: 15px;
}

.copyMethodIdLabel {
  color: #5165e0;
  font-weight: 600;
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
}

.one-line-file-container {
  display: flex;
  gap: 10px;
}

.tooltipIcon {
  position: relative;
  z-index: 11;
  padding: 1px;
}

/* Scrollbar (Reports) */
.custom-scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #7e7e7e #ffffff00;
  
  /* Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #7e7e7e;
    border-radius: 10px;
    border: none;
  }
}

/* rport announcement */
span.report-announcement {
  font-size: 15px;
}