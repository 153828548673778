// Layout Splitter

.handle-bar {
  background: transparent !important;
  @include border-radius(30px);

  &:active,
  &:hover,
  &.handle-bar_clone {
    background-color: $gray-200 !important;
  }

  &.resize-not-allowed:hover {
    background-color: $gray-200;
  }

  .handle-bar_drag {
    border-color: $primary !important;
  }
}

.vertical-resizer {
  width: 2px;
  background-color: $gray-400;
  cursor: col-resize;
}

.Pane:first-child {
  background-color: $white;
  @media screen and (max-width:1248px) {
    width: 0 !important;
  }
}
.Pane:last-child .app-main__outer {
  @media screen and (max-width:1248px) {
    max-width: 100% !important;
  }
}


