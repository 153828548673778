@font-face {
  font-family: "text-security-disc";
  src: url("./fonts/text-security/text-security-disc-compat.eot");
  src: url("./fonts/text-security/text-security-disc-compat.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/text-security/text-security-disc.woff2") format("woff2"),
    url("./fonts/text-security/text-security-disc-compat.ttf")
      format("truetype");
}

/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/muli/Muli_400Regular_Italic.woff2) format("woff2"),
    url(./fonts/muli/Muli_400Regular_Italic.ttf) format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/muli/Muli_400Regular_Italic.woff2) format("woff2"),
    url(./fonts/muli/Muli_400Regular_Italic.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/muli/Muli_700Bold_Italic.woff2) format("woff2"),
    url(./fonts/muli/Muli_700Bold_Italic.ttf) format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/muli/Muli_700Bold_Italic.woff2) format("woff2"),
    url(./fonts/muli/Muli_700Bold_Italic.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(./fonts/muli/Muli_900Black_Italic.woff2) format("woff2"),
    url(./fonts/muli/Muli_900Black_Italic.ttf) format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(./fonts/muli/Muli_900Black_Italic.woff2) format("woff2"),
    url(./fonts/muli/Muli_900Black_Italic.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/muli/Muli_400Regular.woff2) format("woff2"),
    url(./fonts/muli/Muli_400Regular.ttf) format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./fonts/muli/Muli_400Regular.woff2) format("woff2"),
    url(./fonts/muli/Muli_400Regular.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/muli/Muli_700Bold.woff2) format("woff2"),
    url(./fonts/muli/Muli_700Bold.ttf) format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./fonts/muli/Muli_700Bold.woff2) format("woff2"),
    url(./fonts/muli/Muli_700Bold.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(./fonts/muli/Muli_800ExtraBold.woff2) format("woff2"),
    url(./fonts/muli/Muli_800ExtraBold.ttf) format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(./fonts/muli/Muli_800ExtraBold.woff2) format("woff2"),
    url(./fonts/muli/Muli_800ExtraBold.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./fonts/muli/Muli_900Black.woff2) format("woff2"),
    url(./fonts/muli/Muli_900Black.ttf) format("truetype");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./fonts/muli/Muli_900Black.woff2) format("woff2"),
    url(./fonts/muli/Muli_900Black.ttf) format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
